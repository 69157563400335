html {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  background: black;
  margin: 0;
  padding: 0;
  position: relative;
}

main {
  padding-bottom: 800px;
}

.container {
  max-width: 960px;
}

.container-medium {
  width: 600px;
  max-width: 86%;
  margin: 0 auto;
  padding: 0;
}

.container-small {
  width: 400px;
  max-width: 96%;
  margin: 0 auto;
  padding: 0;
}

.main-nav {
  background: rgba(0,0,0,.8);
}

.btn {
  text-transform: uppercase;
}

.logo {
  margin-top: -30px;
}

// Socials
.social a {
  transition: .2s all;
}

.facebook:hover {
  color: #1877F2 !important;
}

.instagram:hover {
  color: #fb50b7 !important;
}

.soundcloud:hover {
  color: #ff8800 !important;
}

.bandcamp:hover {
  color: #629aa9 !important;
}

.spotify:hover {
  color: #1DB954 !important;
}

.apple-music:hover {
  color: #FA2D48 !important;
}

.youtube:hover {
  color: #FF0000 !important;
}

.beatport img {
  height: 1.5rem;
  filter: grayscale(1);
  position: relative;
  top: -2px;
  opacity: .65;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}

.beatport {
  filter: brightness(0.8);
}

.profile-photo {
  width: 220px;
  margin: 1rem auto;
  border-radius: 50%;
  border: 4px solid rgba(255,255,255,.1);
  display: block;
}

.nav-link {
  border-bottom: 4px solid black;
  &.active {
    border-color: rgb(220, 53, 69);
  }
}

.release:hover {
  filter: brightness(1.4);
}

.release-action:hover {
  i {
    opacity: .7;
  }
}

.release-bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 55%;
  min-height: 55%;
  filter: blur(30px);
  opacity: .15;
  z-index: -1;
}

a.list-group-item .btn {
  min-width: 100px;
}

a.list-group-item:hover {
  .btn-secondary {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
}

// Mobile
@media screen and (max-width: 576px) {
  .logo {
    margin-top: 0px;
  }
  .home,
  .release-nav{
    .btn {
      width: 100%;
    }
  }
}